import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import CustomerPortal from './pages/CustomerPortal';
import video from './assets/intro.mp4';


function App() {
  const newLayout = true;

  if (newLayout) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: 'white' }}> {/* Updated background color to white */}
        <video width="50%" height="50%" autoPlay controls style={{ backgroundColor: 'white' }}> {/* Removed muted attribute and added background color */}
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <p style={{ marginTop: '20px' }}>Working on a new website</p> {/* Removed inline color style to use default color */}
      </div>
    )
  }

  return (
    <Router>
      <Navigation />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/customer-portal" element={<CustomerPortal />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
